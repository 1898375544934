<template>
  <div @click="openAiDemo">
    <h5 class="lesson-name">
      {{ item.event_title }}
    </h5>
    <b-row>
      <b-col class="pr-0 col-8">
        <h6 class="text-muted">
          <feather-icon
            v-b-tooltip.hover="$t('users-module.assigned')"
            icon="CalendarIcon"
            size="12"
            style="margin-top: -3px"
          />
          {{ FROM_NOW(item.start_time) }}
        </h6>

        <div class="d-flex"
             style="margin-bottom: 5px"
        >
          <feather-icon
            v-b-tooltip.hover="$t('class-module.custom-class')"
            icon="ListIcon"
            size="12"
            style="margin-top: 3px; margin-right: 5px;"
          />
          <span
            class="class-detail"
            @click="openClassRoom(item.class_id)"
            @click.stop
          >
            {{ item.class?.name || $t('labels.classroom') }}
          </span>
        </div>

        <span class="d-flex">
          <feather-icon
            icon="BookIcon"
            size="12"
            style="margin-top: 5px; margin-right: 5px;"
          />
          <b-badge
            variant="primary"
            size="sm"
            class="calendar"
            @click="openAiDemo"
            @click.stop
          >
            REA
          </b-badge>
        </span>

        <span
          v-if="isLessonLocked"
          class="lesson-completed-badge"
        >
          <feather-icon
            icon="LockIcon"
            size="12"
          />
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'ReaLessonCard',
  components: {
    BRow,
    BCol,
    BBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLessonLocked() {
      return this.item.is_locked
    },
  },
  methods: {
    openClassRoom(classId) {
      this.$router.push(`/class/${classId}`)
    },
    openAiDemo() {
      window.open(`/adv-ai-demo?event_id=${this.item.id}`, '_self')
    },
  },
}
</script>

<style lang="scss" scoped>
.lesson-name {
  margin-bottom: 1rem;
}

.class-detail {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.lesson-completed-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
