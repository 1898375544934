<template>
  <div>
    <!-- <Statistics v-if="statisticsData"
                :today="today"
                :student-info="studentInfo"
                :statistics-data="statisticsData"
    /> -->
    <template v-if="!hasNoAssignment">
      <BOverlay
        :show="isProcessing"
        class="card-filter"
      >
        <b-card
          class="mb-1 filter-card"
          body-class="p-1"
        >
          <div class="d-flex justify-content-between">
            <ul class="nav navbar-nav ">
              <li class="nav-item">
                <feather-icon
                  icon="MenuIcon"
                  size="21"
                  class="d-xl-none"
                  style="margin-right: 5px"
                  @click="showFilters"
                />
                <span class="mr-1">Filter:</span>
              </li>
            </ul>
            <span class="card-filter-date">
              <feather-icon icon="CalendarIcon" /> {{ $t('messages.tableHeader.date') }}: {{ today }}
            </span>
          </div>

          <div
            class="d-md-flex filter-list mb-hide"
          >
            <div>
              <b-form-checkbox
                v-if="studentAssignment.length > 0"
                v-model="assignmentType.student"
                class="custom-control-primary"
                name="check-button"
                inline
                @change="handleCheckboxChange('student')"
              >
                {{ $t('users-module.your-assignment') }} [{{ studentAssignment.length }}]
              </b-form-checkbox>

              <b-form-checkbox
                v-if="classRoomAssignment.length > 0"
                v-model="assignmentType.classRoom"
                class="custom-control-primary"
                name="check-button"
                inline
                @change="handleCheckboxChange('classRoom')"
              >
                {{ $t('users-module.classroom-assignment') }} [{{ classRoomAssignment.length }}]
              </b-form-checkbox>

              <b-form-checkbox
                v-if="followUpAssignment.length > 0"
                v-model="assignmentType.followUp"
                class="custom-control-primary"
                name="check-button"
                inline
                @change="handleCheckboxChange('followUp')"
              >
                {{ $t('follow-up-lessons') }} [{{ followUpAssignment.length }}]
              </b-form-checkbox>

              <b-form-checkbox
                v-if="classTestAssignment.length > 0"
                v-model="assignmentType.classTest"
                class="custom-control-primary"
                name="check-button"
                inline
                @change="handleCheckboxChange('classTest')"
              >
                {{ $t('calendar-module.class-test') }} [{{ classTestAssignment.length }}]
              </b-form-checkbox>
            </div>
          </div>
        </b-card>

        <b-row>
          <!-- Student Assignment -->
          <b-col
            v-if="assignmentType.student && studentAssignment.length > 0"
            md="3"
            class="pr-md-0"
          >
            <template
              v-if="studentAssignment.length == 0"
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('users-module.no-lesson-assigned') }}
              </b-card>
            </template>
            <template
              v-else
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('users-module.your-assignment') }}
              </b-card>

              <b-card
                v-for="item,index of studentAssignment"
                :key="index"
                role="button"
                class="mb-1"
                body-class="pt-1 px-1 pb-0 pb-md-1"
                @click="openLesson(item)"
              >
                <ReaLessonCard v-if="item.event_type === 'rea_event'"
                               :item="item"
                />
                <LessonCard
                  v-else
                  :item="item"
                />
              </b-card>
            </template>

          </b-col>
          <!-- Student Assignment -->
          <!-- ClassRoom Assignment -->
          <b-col
            v-if="assignmentType.classRoom"
            md="3"
            class="pr-md-0"
          >
            <template
              v-if="classRoomAssignment.length == 0"
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('users-module.no-lesson-assigned') }}
              </b-card>
            </template>
            <template
              v-else
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('users-module.classroom-assignment') }}
              </b-card>

              <b-card
                v-for="item,index of classRoomAssignment"
                :key="index"
                role="button"
                class="mb-1"
                body-class="pt-1 px-1 pb-0 pb-md-1"
                @click="openLesson(item)"
              >
                <ReaLessonCard v-if="item.event_type === 'rea_event'"
                               :item="item"
                />
                <LessonCard
                  v-else
                  :item="item"
                />
              </b-card>
            </template>
          </b-col>
          <!-- ClassRoom Assignment -->
          <!-- FollowUp Assignment -->
          <b-col
            v-if="assignmentType.followUp"
            md="3"
            class="pr-md-0"
          >
            <template
              v-if="followUpAssignment.length == 0"
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('no-review-lessons') }}
              </b-card>
            </template>
            <template
              v-else
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('follow-up-lessons') }}
              </b-card>

              <b-card
                v-for="item,index of followUpAssignment"
                :key="index"
                role="button"
                class="mb-1"
                body-class="pt-1 px-1 pb-0 pb-md-1"
                @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
            </template>
          </b-col>
          <!-- FollowUp Assignment -->
          <!-- ClassTest Assignment -->
          <b-col
            v-if="assignmentType.classTest"
            md="3"
            class="pr-md-0"
          >
            <template
              v-if="classTestAssignment.length == 0"
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('no-class-tests') }}
              </b-card>
            </template>
            <template
              v-else
            >
              <b-card
                class="mb-1"
                body-class="assignment-title"
              >
                {{ $t('calendar-module.class-test') }}
              </b-card>

              <b-card
                v-for="item,index of classTestAssignment"
                :key="index"
                role="button"
                class="mb-1"
                body-class="pt-1 px-1 pb-0 pb-md-1"
                @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
            </template>
          </b-col>
          <!-- ClassTest Assignment -->
        </b-row>

      </BOverlay>
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <b-card>
            {{ $t('users-module.no-lesson-assigned') }}
          </b-card>
        </b-col>
      </b-row>
    </template>

  </div>
</template>
<script setup>
import {
  BRow, BCol, BCard,
  // BTabs, BTab,
  BOverlay,
  // BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  onMounted,
  getCurrentInstance,
  // computed,
} from 'vue'
// import i18n from '@/libs/i18n'
import useLessonDashboard from './useLessonDashboard'
// import Statistics from './Statistics.vue'
import LessonCard from './components/LessonCard.vue'
import ReaLessonCard from './components/ReaLessonCard.vue'

const {
  getDashboard,
  today,
  studentAssignment,
  classRoomAssignment,
  hasNoAssignment,
  // statisticsData,
  followUpAssignment,
  isProcessing,
  classTestAssignment,
  // studentInfo,
  assignmentType,
  handleCheckboxChange,
  // assignmentTypeValue,
  // assignments,
  // getAssignmentByType,
  // getDefaultAssignments,
} = useLessonDashboard()

onMounted(() => {
  getDashboard()
  // getDefaultAssignments()
})

const root = getCurrentInstance().proxy.$root

// const assignmentTypes = computed(() => ([
//   {
//     text: i18n.tc('users-module.your-assignment'),
//     value: 'studentAssignment',
//     noLessonText: i18n.tc('users-module.no-lesson-assigned'),
//   },
//   {
//     text: i18n.tc('users-module.classroom-assignment'),
//     value: 'classRoomAssignment',
//     noLessonText: i18n.tc('users-module.no-lesson-assigned'),
//   },
//   {
//     text: i18n.tc('follow-up-lessons'),
//     value: 'followUpAssignment',
//     noLessonText: i18n.tc('no-review-lessons'),
//   },
//   {
//     text: i18n.tc('calendar-module.class-test'),
//     value: 'classTestAssignment',
//     noLessonText: i18n.tc('no-class-tests'),
//   },
// ]))

// eslint-disable-next-line consistent-return
const openLesson = event => {
  console.log(event)
  if (event.is_locked) {
    return null
  }
  const self = root.AUTH_USER()
  const { lesson } = event
  if (event.event_type === 'vocab_words') {
    root.$router.push({
      name: `${self.usertype}-vocab-lab`,
      params: { eventId: event.id, classId: event.class_id },
    })
  } else if (lesson.completed_at) {
    root.$router.push({
      name: `${self.usertype}-course-result`,
      params: {
        classId: event.class_id,
        id: lesson.id,
      },
      query: {
        lesson_id: lesson.id,
        student_id: self.id,
      },
    })
  } else {
    root.$router.push({
      name: `${self.usertype}-lesson`,
      params: { id: event.lesson_id, classId: event.class_id },
      query: {
        event_id: event.id,
        mode: event.event_type,
      },
    })
  }
}

function showFilters() {
  const filterList = document.querySelector('.filter-list')
  if (filterList) {
    filterList.classList.toggle('mb-hide')
  }
}

</script>
<style>
.card-statistics, .card-filter, .statistic-carousel {
  margin-right: -12px;
}

.lesson-image {
  min-width: 70px !important;
  max-width: 70px !important;
  height: 70px !important;
}
.lesson-tabs .nav {
  display: none;
}
.detail-responsive .calendar {
  display:inline;
}
.no-lesson-small {
  display: none;
}
.assignment-title {
  padding: 10px;
}
.statistic-date {
  float:right;
}
.filter-card .filter-list{
  justify-content: flex-start !important;
}
@media(max-width:1200px) and (min-width:940px) {
 .detail-responsive{
  /* padding-left: 45px !important */
 }
 .lesson-image {
    min-width: 60px !important;
    max-width: 60px !important;
    height: 60px !important;
  }
}
@media(max-width:940px) and (min-width:840px) {
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .lesson-image {
    min-width: 55px !important;
    max-width: 55px !important;
    height: 55px !important;
  }
  .detail-responsive .class-detail {
    font-size:10px;
    white-space: break-spaces;
    text-align: left;
  }
  .detail-responsive .calendar {
    font-size:10px;
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .lesson-name {
    font-size: 12px !important;
  }
  h6.text-muted {
    font-size: 11px !important;
  }
  .class-detail {
    font-size: 11px !important;
  }
  .calendar {
    font-size: 10px !important;
  }
}
@media(max-width:840px) and (min-width:768px) {
  .lesson-completed-badge {
    width: 25px;
    height: 25px;
  }
  .lesson-image {
    min-width: 55px !important;
    max-width: 55px !important;
  }
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .detail-responsive .class-detail {
    font-size:10px;
    white-space: break-spaces;
    text-align: left;
  }
  .detail-responsive .calendar {
    font-size:10px;
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .detail-responsive .align-items-baseline span.badge{
    margin-left:0px !important;
    font-size: 11px;
  }
}
@media(max-width:768px) {
  .card-statistics, .card-filter {
    margin-right: 0px;
  }
  .card-statistics, .card-filter .card-filter-date {
    font-size: 12px;
  }
  .lesson-completed-badge {
    width: 25px;
    height: 25px;
  }
  .lesson-image {
    min-width: 100% !important;
    max-width: 100% !important;
    height: 70px !important;
    text-align:center;
  }
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .detail-responsive .align-items-baseline span.badge{
    margin-left:0px !important;
    font-size: 11px;
  }
  .lesson-tabs .nav {
    display: inline-flex;
  }
  .lesson-tabs .nav  li{
    background-color: #fff;
    border-radius: 5px;
    margin-right: 5px;
  }
  .large-followup{
    display:none;
  }
  .no-lesson-small {
    display: inline;
  }
  .no-lesson-large {
    display: none;
  }
  h4.card-title{
    font-size: 13px !important;
  }
  .statistic-date {
    float:none;
    font-size: 12px;
    display: block;
  }
  .mb-hide {
    display: none;
  }
  .filter-list>div{
    display: grid;
  }
}
</style>
