<template>
  <div>
    <h5 class="lesson-name">
      {{ item.lesson.name }}
    </h5>
    <b-row>
      <b-col
        class="pr-0 col-8"
      >
        <h6 class="text-muted">
          <feather-icon
            v-b-tooltip.hover="$t('users-module.assigned')"
            icon="CalendarIcon"
            size="12"
            style="margin-top: -3px"
          />
          {{ FROM_NOW(item.start_time) }}
        </h6>
        <!-- <p v-if="item.current_loader && item.current_loader.completed_at"
           class="text-success"
        >
          You {{ $t("student-test-module.completed") }} {{ FROM_NOW(item.current_loader.completed_at) }}
        </p> -->
        <div class="d-flex"
             style="margin-bottom: 5px"
        >
          <feather-icon
            v-b-tooltip.hover="$t('class-module.custom-class')"
            icon="ListIcon"
            size="12"
            style="margin-top: 3px; margin-right: 5px;"
          />
          <span
            class="class-detail"
            @click="openClassRoom(item.class_id)"
            @click.stop
          >
            {{ item.class?.name || $t('labels.classroom') }}
          </span>
        </div>
        <span class="d-flex">
          <feather-icon
            icon="BookIcon"
            size="12"
            style="margin-top: 5px; margin-right: 5px;"
          />
          <b-badge
            v-b-tooltip="item.lesson.lesson_group.course.name"
            variant="primary"
            size="sm"
            class="calendar"
            @click="openLessonGroup(item)"
            @click.stop
          >
            <span>
              {{ item.lesson.lesson_group.course.name.trim().length > 15 ? item.lesson.lesson_group.course.name.trim().slice(0,15) + '...' : item.lesson.lesson_group.course.name.trim() }}
            </span>
          </b-badge>
        </span>
        <span
          v-if="item.current_loader && item.current_loader.completed_at"
          class="d-flex mt-1"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="12"
            style="margin-top: 5px; margin-right: 5px;"
          />
          <b-badge
            variant="success"
            size="sm"
            class="calendar"
            @click.stop="showResultModal = true"
          >
            {{ $t('View Report') }}
          </b-badge>
        </span>
        <span
          v-if="isLessonLocked"
          class="lesson-completed-badge"
        >
          <feather-icon
            icon="LockIcon"
            size="12"
          />
        </span>
        <EventsTagsView :tags="item.event_tags || []" />
      </b-col>
      <b-col
        v-if="item.lesson"
        class="mb-1 mb-md-0 col-4 pl-0"
      >
        <Avatar v-if="item.lesson.image"
                :image="item.lesson.image"
                :rounded="true"
                :size="`100%`"
                class="lesson-image"
        />
        <b-avatar v-else
                  variant="light-warning"
                  square
                  class="lesson-image"
        >
          <span>{{ item.lesson?.name?.charAt(0) }}</span>
        </b-avatar>
      </b-col>
    </b-row>

    <completed-result-view
      :params="{
        loaderId: item.current_loader?.id,
        classId: item.class_id,
        id: item.lesson.id,
        event_id: item.id,
      }"
      :student-id="self.usertype === 'student' ? self.id : Number($route.params.id)"
      :view-detail-table="false"
      :show-result="showResultModal"
      @close="showResultModal = false"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BAvatar,
  BBadge,
} from 'bootstrap-vue'
import Avatar from '@/views/common/components/Avatar.vue'
import CompletedResultView from '@/views/v2/common/class/components/CompletedResultView.vue'
import { getUserData } from '@/auth/utils'
import EventsTagsView from '@/views/common/components/EventsTagsView.vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BBadge,
    Avatar,
    CompletedResultView,
    EventsTagsView,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
      showResultModal: false,
    }
  },
  computed: {
    isLessonLocked() {
      return !!this.item.is_locked
    },
  },
  methods: {
    openCalendar(roomId) {
      this.$router.push({ name: 'student-class-calendar', params: { classId: roomId } })
    },
    openLessonGroup(item) {
      const courseId = item.lesson.lesson_group.course.id
      const classId = item.class_id
      const group = item.lesson.lesson_group.name
      this.$router.push({
        name: 'student-lessons',
        params: { id: courseId, classId },
        query: { group },
      })
    },
    openClassRoom(roomId) {
      this.$router.push({ name: 'student-view-students', params: { id: roomId } })
    },
  },
}
</script>
<style scoped>
.lesson-name {
  font-size: 14px;
}
h6.text-muted {
  font-size: 12px;
}
.class-detail {
  font-size: 12px;
}
.lesson-completed-badge {
  position: absolute;
  top: 28px;
  right: 0px;
  width: 30px;
  height: 30px;
}

</style>
